<script setup lang="ts">
import type { Editor } from "@tiptap/vue-3";

defineProps<{
  editor?: Editor;
  editorIsEmpty: boolean;
}>();

const emits = defineEmits(["send", "cancel"]);
</script>

<template>
  <ToolbarRoot class="flex gap-1 p-1 justify-end" aria-label="Formatting options">
    <ToolbarButton asChild>
      <BasicButton
        size="sm"
        variant="highlight"
        icon="lucide-send"
        tooltip="editor.toolbar2.send"
        :disabled="editorIsEmpty"
        class="ml-auto"
        @click="emits('send')"
      />
    </ToolbarButton>
    <ToolbarButton asChild>
      <BasicButton
        size="sm"
        variant="white"
        icon="lucide-x"
        :disabled="editorIsEmpty"
        tooltip="editor.toolbar2.cancel"
        @click="emits('cancel')"
      />
    </ToolbarButton>
  </ToolbarRoot>
</template>
