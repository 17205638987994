<script lang="ts" setup>
import type { AskCheckbotMutation } from "#gql";
import { nanoid } from "nanoid";

import Chat from "./Chat.vue";
import Editor from "./Editor.vue";

defineProps<{
  class?: string;
}>();
const target = ref();
usePageNavigation(target, "chat");

const checkbotStore = useCheckbotStore();

onMounted(() => {
  if (checkbotStore.comments.length === 0) {
    setTimeout(() => {
      checkbotStore.addComment({
        id: nanoid(),
        type: "checkbot",
        createdAt: new Date().toISOString(),
        text: t("checkbot.welcome")
      });
    }, 0);
  }
});

const { mutate, isPending } = useAskCheckbot({
  onSuccess: (data: AskCheckbotMutation) => {
    const content = data?.sendMessageToCheckbotMessagePost;

    checkbotStore.addComment({
      id: nanoid(),
      type: "checkbot",
      createdAt: new Date().toISOString(),
      hasEvaluation: content?.type !== "unknown",
      ...getContent(content)
    });
  }
});
const { t } = useI18n();

function handleSend(text: string) {
  checkbotStore.addComment({
    id: nanoid(),
    type: "user",
    text,
    createdAt: new Date().toISOString()
  });
  mutate(text);
}

type CheckbotResponseType = "learning-material" | "fact-check" | "unknown";

function getContent(content: AskCheckbotMutation["sendMessageToCheckbotMessagePost"]) {
  switch (content?.type as CheckbotResponseType) {
    case "learning-material":
      return {
        text: t(`checkbot.learning-material.${content?.data?.length || 0 > 1 ? "plural" : "singular"}`),
        links: content?.data?.map((link) => ({
          url: link?.link || "",
          title: link?.summary || ""
        }))
      };
    case "fact-check":
      return {
        text: t(`checkbot.fact-check.${content?.data?.length || 0 > 1 ? "plural" : "singular"}`),
        links: content?.data?.map((link) => ({
          url: link?.url || "",
          title: link?.label || ""
        }))
      };
    case "unknown":
    default:
      return { text: t("checkbot.unknown") };
  }
}
</script>

<template>
  <section
    id="chat"
    ref="target"
    :class="
      cn(
        'max-md:w-[100vw] md:max-lg:w-[50vw] lg:max-3xl:w-[33.333vw] 3xl:w-[25vw]',
        'h-full flex shrink-0',
        'max-xl:snap-start overflow-hidden',
        $props.class
      )
    "
  >
    <ClientOnly>
      <section :class="cn('basis-0 grow h-full flex flex-col')">
        <section :class="cn('relative grow w-full flex flex-col overflow-hidden')">
          <Chat :isLoading="isPending" />
          <Editor class="m-5 -mt-1" :isLoading="isPending" @send="handleSend" />
        </section>
      </section>
    </ClientOnly>
  </section>
</template>
