import { defineStore } from "pinia";

export type TComment = {
  id: string;
  createdAt: string;
  type: "user" | "checkbot";
  text: string;
  links?: { url: string; title: string }[];
  hasEvaluation?: boolean;
};

export const useCheckbotStore = defineStore("checkbot", {
  state: () => ({
    comments: [] as TComment[]
  }),
  actions: {
    addComment(comment: TComment) {
      this.comments.push(comment);
    }
  },
  getters: {},
  persist: {
    storage: piniaPluginPersistedstate.sessionStorage()
  }
});
