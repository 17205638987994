import type { AskCheckbotMutation } from "#gql";

import { useMutation } from "@tanstack/vue-query";

import type { GqlErrorHandlingOptions } from "./useGqlErrorHandling.js";

type Options = {
  errorHandling?: GqlErrorHandlingOptions;
  onSuccess: (data: AskCheckbotMutation) => void;
};

export default function useAskCheckbot(options?: Options) {
  async function mutationFn(text: string) {
    const res = await GqlAskCheckbot({ text });
    return res;
  }
  const context = useMutation({
    mutationFn,
    onSuccess: options?.onSuccess
  });
  useGqlErrorHandling(context.error, context.data, options?.errorHandling);
  return context;
}
