<script setup lang="ts">
import { Icon } from "@iconify/vue";
import Placeholder from "@tiptap/extension-placeholder";
import { useEditor, EditorContent } from "@tiptap/vue-3";

import Toolbar from "./Toolbar.vue";
import TIP_TAP_EXTENSIONS from "~/utils/tipTap";

defineProps<{
  class?: string;
  isLoading?: boolean;
}>();

const emits = defineEmits<{
  (event: "send", content: string): void;
  (event: "cancel"): void;
}>();

const { t } = useI18n();

const editor = useEditor({
  content: "",
  extensions: [
    ...TIP_TAP_EXTENSIONS,
    Placeholder.configure({
      placeholder: t("editor.placeholder")
    })
  ],
  editorProps: {
    attributes: {
      class: "content"
    },
    handleDOMEvents: {
      click: (_, event) => {
        event.stopImmediatePropagation();
      },
      keydown: (_, event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          const isEmpty = !editor.value?.state.doc.textContent.trim().length;
          if (isEmpty) return;
          sendComment();
        }
      }
    }
  }
});
const editorIsEmpty = computed(() => !editor.value?.state.doc.textContent.trim().length);

function sendComment() {
  emits("send", editor.value?.getText() || "");
  editor.value?.commands.clearContent(true);
  editor.value?.commands.focus();
}

function cancelComment() {
  emits("cancel");
  editor.value?.commands.clearContent(true);
  editor.value?.commands.focus();
}

function handleClick() {
  editor.value?.commands.focus("end");
}
onBeforeUnmount(() => {
  editor.value?.destroy();
});
</script>

<template>
  <div
    :class="
      cn(
        `
      relative z-10 justify-self-stretch
      shrink-0 max-h-[calc(100vh-10rem)]
      flex flex-col
      rounded-lg bg-neutral-0 transition
      border border-neutral-2 focus-within
    `,
        $props.class
      )
    "
    @click="handleClick"
  >
    <div class="grow scroll-area">
      <p v-if="isLoading" class="flex items-center gap-1.5 p-2.5 text-neutral-5">
        <Icon icon="lucide:loader" class="animate-spin size-4" />
        {{ t("editor.loading") }}
      </p>
      <editor-content v-else :editor class="[&>div]:ring-0 grow p-2.5" />
    </div>
    <Toolbar :editorIsEmpty :editor @send="sendComment" @cancel="cancelComment" />
  </div>
</template>
