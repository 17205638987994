<script lang="ts" setup>
import { Icon } from "@iconify/vue";

import Comment from "./Comment.vue";

const props = defineProps<{
  isLoading: boolean;
}>();
const target = ref();
usePageNavigation(target, "chat");

const checkbotStore = useCheckbotStore();

const commentsRef = ref<HTMLElement | null>(null);
useMutationObserver(
  commentsRef,
  (mutations) => {
    const comment = mutations[0].target as HTMLElement;
    comment?.scrollIntoView({ behavior: "smooth", block: "end" });
  },
  { childList: true }
);

const chatContainer = ref<HTMLElement>();

watch(
  () => checkbotStore.comments,
  () => {
    nextTick(() => {
      if (chatContainer.value) {
        chatContainer.value.scrollTo({ top: chatContainer.value.scrollHeight, behavior: "smooth" });
      }
    });
  },
  { deep: true }
);
watch(
  () => props.isLoading,
  () => {
    nextTick(() => {
      if (chatContainer.value) {
        chatContainer.value.scrollTo({ top: chatContainer.value.scrollHeight, behavior: "smooth" });
      }
    });
  }
);
onMounted(() => {
  if (chatContainer.value) {
    chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
  }
});
</script>

<template>
  <div ref="chatContainer" class="relative scroll-area grow">
    <div class="w-full">
      <ul :class="cn('px-4 pt-2 pb-6 flex flex-col gap-3')">
        <Comment v-for="comment in checkbotStore.comments" :key="comment.id" v-bind="comment" />
        <li v-if="isLoading" class="flex pl-2 gap-2 items-center">
          <Icon icon="lucide:loader" class="size-6 animate-spin" /> {{ $t("checkbot.thinking") }}
        </li>
      </ul>
    </div>
  </div>
</template>
