<script lang="ts" setup>
import { Icon } from "@iconify/vue";

import type { TComment } from "@/stores/checkbot.js";

defineProps<TComment>();

const { username } = useSessionInfo();

const showEvaluation = ref(true);
function handleEvaluation(value: "helpful" | "unhelpful") {
  showEvaluation.value = false;
  console.log(value);
}
</script>

<template>
  <li :id :class="cn('flex gap-3 relative hover:bg-neutral-0 transition p-2 rounded-lg group')">
    <Avatar v-if="type === 'user'" :username="username" size="lg" class="shrink-0" />
    <Icon v-else class="shrink-0 size-9 p-1 bg-neutral-8 text-neutral-0 rounded-lg" icon="lucide:bot" />
    <div class="flex flex-col items-top gap-2">
      <div class="flex flex-col gap-1">
        <div class="flex items-baseline gap-3">
          <h3 class="text-base font-semibold">{{ type === "user" ? username : "Checkbot" }}</h3>
          <p class="small text-neutral-5"><Date :date="createdAt" time /></p>
        </div>
        <p :class="cn('content font-serif')">
          {{ text }}
        </p>
      </div>
      <ul v-if="links && links.length > 0" class="flex flex-col gap-2 mt-2">
        <li v-for="link in links" :key="link.url">
          <a
            :href="link.url"
            target="_blank"
            class="flex px-4 py-3 bg-neutral-0 rounded-lg border hover:bg-neutral-1 transition"
          >
            <Icon class="shrink-0 relative top-1 mr-4" icon="lucide:external-link" />{{ link.title }}
          </a>
        </li>
      </ul>
      <div
        v-if="type === 'checkbot' && hasEvaluation && showEvaluation"
        class="flex flex-col gap-2 mt-2 leading-[1.3] text-neutral-5"
      >
        {{ $t("checkbot.evaluation") }}
        <div class="flex gap-2">
          <BasicButton
            class="w-12"
            icon="lucide:thumbs-up"
            size="sm"
            :aria-label="$t('checkbot.yes')"
            @click="handleEvaluation('helpful')"
          />
          <BasicButton
            class="w-12"
            icon="lucide:thumbs-down"
            size="sm"
            :aria-label="$t('checkbot.no')"
            @click="handleEvaluation('unhelpful')"
          />
        </div>
      </div>
    </div>
  </li>
</template>
